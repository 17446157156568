@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

body{
  background: #f3f3f3;
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  height: 300px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #d5a9a0;
  border-radius: 6px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #be978f;
}

.custom-progress-bar{
  background-color: #8CC4C5 !important;
}

.product-grid {
  max-width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.admin-panel-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin: 20px 0;
  position: relative;
  padding: 10px;
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  margin-bottom: 2rem;
  display: none;
  /* background: black; */
}

.admin-panel-title::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  background-color: #d5a9a0;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

.add-product-button {
  background-color: #2C3E50;
  color: white;
  border: none;
  /* border-radius: 50%;  */
  width: 40px;
  height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fixed-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000; /* Ajuste o valor do z-index conforme necessário */
}

.add-product-button:hover {
  background-color: #274a6d;
}

.add-product-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 20%;
}

.add-product-input {
  padding: 12px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid #BDC3C7;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.add-product-input:focus {
  border-color: #3498DB;
}

.add-product-buttons {
  display: flex;
  justify-content: flex-end;
}

.add-product-submit,
.add-product-cancel {
  padding: 10px 20px;
  margin-left: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add-product-submit {
  background-color: #2C3E50;
  color: white;
}

.add-product-cancel {
  background-color: #E74C3C; 
  color: white;
}

.add-product-submit:hover,
.add-product-cancel:hover {
  background-color: #274a6d;
}

.add-product-select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  color: #757575;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.add-product-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.add-product-select option {
  padding: 10px;
}


.search-input {
  width: 30%;
  padding: 0 2rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 25px;
  overflow: hidden;
}

  .search-input input[type="text"] {
    flex: 1;
    padding: 12px;
    font-size: 16px;
    border: none;
    outline: none;
    background: transparent;
  }

  .category-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.filter-checkbox {
  display: flex;
  align-items: center;
  margin-right: 20px;
}


.filter-toggle {
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  gap: .7rem;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.filter-button, .clear-filters-button {
  background-color: #2C3E50;
  color: white;
  border: none;
  padding: 10px 20px;
  /* border-radius: 8px; */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.filter-button {
  margin-right: 10px;
}

.clear-filters-button {
  background-color: #c22736;
}

.clear-filters-button i {
  margin-right: 5px;
}

.filter-button:hover {
  background-color: #274a6d;
}

.fa-filter {
  margin-right: 5px;
}

.filter-checkbox {
  margin-right: 10px;
}

.filter-checkbox input {
  margin-right: 5px;
}

.product-card {
  border: 1px solid #e0e0e0;
  padding: 20px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.product-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: #d5a9a0;
  border-radius: 16px 0 0 16px;
}

.product-card:hover {
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
}

.product-card-icons {
  position: absolute;
  top: 10px;
  right: 10px;
}

.checkbox-container {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 35px;
  height: 35px;
}

.product-card-icons .edit-icon,
.product-card-icons .delete-icon {
  font-size: 1.2rem;
  margin-left: 10px;
  cursor: pointer;
  color: #2C3E50;
  transition: color 0.3s ease;
}

.product-card-icons .delete-icon {
  color: #2C3E50;
}

.product-card-icons .edit-icon:hover {
  color: #0056b3;
}

.product-card-icons .delete-icon:hover {
  color: #c0392b;
}

.product-card img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
  transition: transform 0.3s;
}

.product-card img:hover {
  transform: scale(1.05);
}

.product-card button {
  margin-top: 10px;
  padding: 12px 24px;
  background-color: #274a6d;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s;
  font-size: 1rem;
}

.product-card button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

.product-card h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.product-card form {
  margin-top: 10px;
}

.product-card label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.product-card input,
.product-card select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.product-card input:focus,
.product-card select:focus {
  border-color: #007bff;
}

.product-card button[type="submit"],
.product-card button[type="button"] {
  padding: 12px 24px;
  margin-top: 10px;
  margin-right: 10px;
}

.product-list-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  color: #333;
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
}